/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { getChatAnswer, fetchSpeech, stopAudio } from "../../api/chat";
import { getChatConfigs, getSpeechConfigs } from "../../config/database/chat";
import "./voiceAssistant.css";
import Typewriter from "../../components/Typewriter";
import audioWaveGif from "../../assets/Images/audio-wave.gif";

const VoiceAssistant = () => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [chatConfigData, setChatConfigData] = useState();
  const [divHeight, setDivHeight] = useState(0);
  const [speechConfigData, setSpeechConfigData] = useState({});
  const [speakingStates, setSpeakingStates] = useState({});
  const [recognition, setRecognition] = useState(null);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [isRecognizing, setIsRecognizing] = useState(false);
  const [isWordRecognized, setIsWordRecognized] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [typewriter, setTypewriter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  // Refs
  const divRef = useRef(null);
  // const messageContainerRef = useRef(null);
  const inputValueRef = useRef();
  const chatConfigDataRef = useRef(null);
  const speechConfigDataRef = useRef(null);
  const pendingMessagesRef = useRef(null);

  // Speech Config Data
  useEffect(() => {
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      speechConfigDataRef.current = configData;
      setSpeechConfigData(configData);
    };

    fetchSpeechConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Chat Config Data
  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      chatConfigDataRef.current = data;
      setChatConfigData(data);
    };

    fetchChatConfig();
  }, []);

  // Height Update
  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  // Check Device Type
  useEffect(() => {
    const checkIfTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };

    checkIfTouchDevice();
  }, []);

  // For InputValue Ref
  useEffect(() => {
    inputValueRef.current = inputValue;
  }, [inputValue]);

  const initializeSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition not supported in this browser.");
      return;
    }

    const recognitionInstance = new window.webkitSpeechRecognition();
    recognitionInstance.continuous = true;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = "en-US";

    recognitionInstance.onstart = () => {
      setIsRecognizing(true);
    };

    recognitionInstance.onresult = (event) => {
      let interim = "";
      let final = "";
      for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          final += transcript;
        } else {
          interim += transcript;
        }
      }

      // Set Input based on platform (Specially android)
      if (window.navigator.platform.toLowerCase().includes("linux arm")) {
        setInputValue(event.results[event.results.length - 1][0].transcript);
      } else {
        setInputValue(final + interim);
      }
      setIsWordRecognized(final.length > 0 || interim.length > 0);
    };

    recognitionInstance.onerror = (event) => {
      console.error("Speech recognition error", event);
    };

    recognitionInstance.onend = () => {
      setIsRecognizing(false);
      setIsWordRecognized(false);
      if (inputValueRef.current.trim()) {
        sendMessage(inputValueRef.current.trim());
      }
    };

    setRecognition(recognitionInstance);

    // Start recognition on function call
    recognitionInstance.start();
  };

  // Send Message to Server
  const sendMessage = async (data) => {
    if (!data) return;

    if (
      messages.length > 1 &&
      messages[messages.length - 1].content === "Typing..."
    ) {
      return;
    }

    const userMessage = {
      role: "user",
      content: data,
    };
    // Include user message
    const newMessages = [...messages, userMessage];

    // Typing indicator
    const typingMessage = {
      role: "assistant",
      content: "Typing...",
      typing: true,
    };

    setMessages([typingMessage]);
    setInputValue("");
    setTypewriter(true);

    const relevantMessages = newMessages.slice(-7);
    setIsResponding(true);

    try {
      const response = await getChatAnswer({
        messages: relevantMessages,
        ...chatConfigDataRef.current,
      });
      const assistantReply = {
        role: "assistant",
        content: response.choices[0].message.content,
      };
      // const updatedMessages = [...newMessages, assistantReply];
      const updatedMessages = [assistantReply];
      // setMessages(updatedMessages);
      pendingMessagesRef.current = updatedMessages;

      speakMessage(assistantReply, updatedMessages.length - 1);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      setIsResponding(false);
      const updatedMessagesWithError = [errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  // Speak the response from server
  const speakMessage = async (message, index) => {
    setSpeakingStates((prev) => ({ ...prev, [index]: true }));
    setIsSpeaking(true);
    await fetchSpeech(
      {
        text: message.content,
        ...speechConfigDataRef.current,
      },
      handleChunkFetched
    );
    setSpeakingStates((prev) => ({ ...prev, [index]: false }));
    setIsSpeaking(false);
    setTypewriter(false);
  };

  const handleChunkFetched = () => {
    if (pendingMessagesRef.current) {
      setIsResponding(false);
      setMessages(pendingMessagesRef.current);
      pendingMessagesRef.current = null;
    }
  };

  const handleStopSpeech = () => {
    stopAudio();
    setSpeakingStates({});
    setIsRecognizing(false);
    setIsSpeaking(false);
    setMessages([]);
  };

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const typewriterComponent = useMemo(() => {
    return (
      <Typewriter texts={[messages[0]?.content]} speed={35} startDelay={0} />
    );
  }, [messages[0]?.content]);

  useEffect(() => {
    const button = document.getElementById("voice-assistant-button");

    const handleTouchStart = (e) => {
      e.preventDefault();
      if (!isRecognizing && !isSpeaking && !isResponding) {
        initializeSpeechRecognition();
      }
    };

    const handleTouchEnd = (e) => {
      e.preventDefault();
      if (isRecognizing) {
        recognition.stop();
      }
    };

    button.addEventListener("touchstart", handleTouchStart);
    button.addEventListener("touchend", handleTouchEnd);

    return () => {
      button.removeEventListener("touchstart", handleTouchStart);
      button.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isRecognizing, isSpeaking, isResponding, recognition]);

  return (
    <div className="flex flex-col">
      <div ref={divRef}></div>
      <div
        className={`flex justify-center items-center w-full
      bg-gradient-to-r from-[#232526] to-[#414345]
      px-[5%] md:px-[10%]`}
        style={{ height: `calc(100vh - ${divHeight}px)` }}
      >
        <div className={`flex justify-center items-center flex-row w-full`}>
          {/* Speech Text */}
          <div className="flex justify-center items-center w-[50%]">
            <div className="w-[100%] md:max-w-[85%] max-h-[80vh] overflow-y-auto">
              <p
                className={`flex items-center text-light_gray text-[16px] leading-[24px] md:text-[20px] md:leading-[32px] font-poppins font-[500] mt-[20px]`}
              >
                {isRecognizing
                  ? inputValue
                  : messages[0]?.content &&
                    (messages[0]?.content && typewriter
                      ? typewriterComponent
                      : messages[0]?.content)}
              </p>
            </div>
          </div>

          {/* Mic Button */}
          <div className="flex flex-col justify-center items-center w-[50%]">
            <div className=" flex justify-center items-center">
              {(isWordRecognized || isSpeaking) && !isResponding && (
                <div
                  className="absolute pulse-ring overflow-auto w-[80px] h-[80px] 
              md:w-[110px] md:h-[110px] lg:w-[150px] lg:h-[150px]"
                ></div>
              )}

              <button
                id="voice-assistant-button"
                className={`relative flex justify-center items-center overflow-hidden rounded-[50%] w-[80px] h-[80px] 
              md:w-[110px] md:h-[110px] lg:w-[150px] lg:h-[150px] shadow-lg border-[2px] cursor-pointer
              ${isWordRecognized ? "border-none" : "border-[#FFF]"}
              ${isWordRecognized ? "bg-[#FF0000]" : "bg-[#38393B]"}
              `}
                style={{
                  transition: "all .5s ease",
                }}
                onClick={() => {
                  if (isSpeaking && !isResponding) {
                    handleStopSpeech();
                  }
                }}
                onMouseDown={() => {
                  if (!isRecognizing && !isSpeaking && !isResponding) {
                    initializeSpeechRecognition();
                  }
                }}
                onMouseUp={() => {
                  if (isRecognizing) {
                    recognition.stop();
                  }
                }}
                onTouchEnd={() => {
                  if (isSpeaking && !isResponding) {
                    handleStopSpeech();
                  }
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {!isTouchDevice ? (
                  <div className="flex items-center justify-center cursor-pointer">
                    {isResponding ? (
                      <img
                        src={audioWaveGif}
                        alt="wave"
                        className="text-[24px] md:text-[36px] lg:text-[45px]"
                        style={{ filter: "invert(1)" }}
                      />
                    ) : isSpeaking ? (
                      isHovered ? (
                        <i
                          className="fa-solid fa-stop text-[24px] md:text-[36px] lg:text-[45px]"
                          style={{
                            color: "#FFF",
                          }}
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className={`fa-solid fa-volume-high text-[24px] md:text-[36px] lg:text-[45px]`}
                          style={{
                            color: "#FFF",
                          }}
                          aria-hidden="true"
                        ></i>
                      )
                    ) : (
                      <i
                        className={`fa fa-microphone text-[24px] md:text-[36px] lg:text-[45px]`}
                        style={{
                          color: isRecognizing
                            ? isWordRecognized
                              ? "#FFF"
                              : "#FF0000"
                            : "#FFF",
                        }}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center justify-center cursor-pointer">
                    {isResponding ? (
                      <img
                        src={audioWaveGif}
                        alt="wave"
                        className="text-[24px] md:text-[36px] lg:text-[45px]"
                        style={{ filter: "invert(1)" }}
                      />
                    ) : isSpeaking ? (
                      <i
                        className={`fa-solid fa-volume-high text-[24px] md:text-[36px] lg:text-[45px]`}
                        style={{
                          color: "#FFF",
                        }}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className={`fa fa-microphone text-[24px] md:text-[36px] lg:text-[45px]`}
                        style={{
                          color: isRecognizing
                            ? isWordRecognized
                              ? "#FFF"
                              : "#FF0000"
                            : "#FFF",
                        }}
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                )}
              </button>
            </div>
            {isSpeaking && !isResponding && isTouchDevice && (
              <div className="text-white mt-[30px] text-[12px] md:text-[14px]">
                Tap the button to stop listening.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceAssistant;
