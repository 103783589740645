import React, { useEffect, useState } from "react";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

const Typewriter = React.memo(({ texts, speed, startDelay }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let timeout;
    const typeText = (text, index) => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text.charAt(index));
        timeout = setTimeout(() => typeText(text, index + 1), speed);
      }
    };

    const startTyping = () => {
      setDisplayedText("");
      typeText(texts[0], 0);
    };

    const delayTimeout = setTimeout(startTyping, startDelay);

    return () => {
      clearTimeout(timeout);
      clearTimeout(delayTimeout);
    };
  }, [texts, speed, startDelay]);

  let lines = displayedText.split("\n");
  const md = new MarkdownIt({
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return (
            '<pre class="hljs"><code>' +
            hljs.highlight(lang, str, true).value +
            "</code></pre>"
          );
        } catch (__) {}
      }

      return (
        '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
      );
    },
  });
  return (
    <span
      className={`flex flex-col text-[14px] font-poppins font-[400] md:text-[18px] md:leading-[20px] `}
    >
      {lines.map((line, index) => {
        const markdownLine = md.render(line);
        if (markdownLine.length === 0) {
          return <div key={index} className="mt-5" />;
        }
        return (
          <div
            key={index}
            className="leading-[24px]"
            dangerouslySetInnerHTML={{ __html: markdownLine }}
          />
        );
      })}
    </span>
  );
});

export default Typewriter;
