import axios from "axios";

export const getRssFeed = async (data) => {
  try {
    const response = await axios.get(
      "https://us-central1-hem-tech.cloudfunctions.net/getFeedData",
      {
        params: {
          url: data,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
