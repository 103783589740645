import {
  doc,
  query,
  where,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";

import { db } from "../../firebase";

async function getFeedUrls() {
  try {
    const rssConfigDocRef = doc(db, "rssConfig", "configDetails");
    const rssConfigDoc = await getDoc(rssConfigDocRef);
    const rssConfigData = rssConfigDoc.data();
    const feedUrlOrder = rssConfigData?.feedUrlOrder || [];

    const pagesRef = collection(rssConfigDocRef, "feedUrls");
    const queryActive = query(pagesRef, where("status", "==", "active"));
    const pageQuerySnapshot = await getDocs(queryActive);
    const pageDocs = pageQuerySnapshot.docs;

    const pageDocsMap = pageDocs.reduce((map, doc) => {
      map[doc.id] = doc;
      return map;
    }, {});

    const feedUrls = feedUrlOrder
      .map((id) => {
        const feedUrlDoc = pageDocsMap[id];
        const feedUrlData = feedUrlDoc?.data();
        if (!feedUrlData) return null;

        return { id: feedUrlDoc.id, ...feedUrlData };
      })
      .filter(Boolean);

    return feedUrls;
  } catch (error) {
    console.error("Error fetching feed URLs:", error);
    return [];
  }
}

export { getFeedUrls };
