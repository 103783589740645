import React, { useState } from "react";
import "./styles.css";
import { fetchSpeech, stopAudio } from "../../api/chat";
import { ROBOT_ICON, USER_ICON, REPEAT, STOP_ICON } from "../../assets/Images";
import audioWaveGif from "../../assets/Images/audio-wave.gif";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";

function ChatMessage({
  message,
  speechConfigData,
  isSpeaking,
  index,
  setSpeakingStates,
  isReSpeaking,
  setIsReSpeaking,
  isMicOn,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleFetchSpeech = async (message) => {
    setIsReSpeaking({});
    setSpeakingStates({});
    if (isMicOn) {
      setIsReSpeaking((prev) => ({ ...prev, [index]: true }));
      await fetchSpeech({ text: message.content, ...speechConfigData });
      setIsReSpeaking((prev) => ({ ...prev, [index]: false }));
    }
  };
  const handleStopFetchingSpeech = () => {
    stopAudio();
    setIsReSpeaking({});
    setSpeakingStates({});
  };
  let messageString = message.content;
  let lines = messageString.split("\n");

  const md = new MarkdownIt({
    highlight: function (str, lang) {
      if (lang && hljs.getLanguage(lang)) {
        try {
          return (
            '<pre class="hljs"><code>' +
            hljs.highlight(lang, str, true).value +
            "</code></pre>"
          );
        } catch (__) {}
      }

      return (
        '<pre class="hljs"><code>' + md.utils.escapeHtml(str) + "</code></pre>"
      );
    },
  });
  return (
    <div className="mb-[20px]">
      {message.role === "assistant" ? (
        <div className="flex justify-start items-center">
          <div className="flex">
            <div className="flex shrink-0 justify-center items-center shadow-xl p-[5px] w-[30px] h-[30px] md:w-[42px] md:h-[42px] rounded-[25px] border-[2px] border-secondary mr-[10px]">
              <ROBOT_ICON className={`flex fill-secondary w-full h-full`} />
            </div>

            <div
              className={`flex flex-col text-light_gray px-[10px] py-[5px] text-[14px] font-poppins font-[400] md:text-[18px] md:leading-[20px] rounded-r-1x rounded-bl-1x shadow-assist mr-[12px] md:mr-[15px] ${
                message.type && message.type === "error"
                  ? "bg-error"
                  : "bg-secondary"
              }`}
            >
              {lines.map((line, index) => {
                const markdownLine = md.render(line);
                return (
                  <div
                    key={index}
                    className={`${
                      lines.length === index + 1 ? "" : ""
                    } mt-[5px]`}
                    dangerouslySetInnerHTML={{ __html: markdownLine }}
                  />
                );
              })}
            </div>
          </div>

          <div
            onClick={() => {
              (isReSpeaking || isSpeaking) && isHovered
                ? handleStopFetchingSpeech()
                : handleFetchSpeech(message);
            }}
            className={`w-[30px] shrink-0 ${
              !(isReSpeaking || isSpeaking) && "cursor-pointer"
            }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {message.typing === true ? (
              <div className="container">
                <div className="dot" />
              </div>
            ) : isReSpeaking || isSpeaking ? (
              isHovered ? (
                <STOP_ICON />
              ) : (
                <img src={audioWaveGif} alt="wave" />
              )
            ) : (
              <REPEAT />
            )}
          </div>
        </div>
      ) : (
        <div className="flex justify-end">
          <div className="flex">
            <p
              className={`flex items-center text-light_gray bg-primary px-[10px] py-[5px] text-[14px] leading-[16px] font-poppins font-[400] md:text-[18px] md:leading-[20px] rounded-l-1x rounded-br-1x shadow-user ml-[40px] md:ml-[50px] ${
                message.typing ? "typing-indicator" : ""
              }}`}
            >
              {message.content}
            </p>
            <div className="flex shrink-0 justify-center items-center shadow-xl p-[5px] w-[30px] h-[30px] md:w-[42px] md:h-[42px] rounded-[25px] border-[2px] border-primary ml-[10px]">
              <USER_ICON className="fill-primary h-full w-full" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatMessage;
