import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { POWER_OFF } from "../../assets/Images";
import { handleSignOut } from "../../config/database/authentication";

import Confirmation from "../Modal/Confirmation";

const SignOut = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <button
        className={`flex justify-center items-center w-[35px] h-[35px] md:w-[45px] md:h-[45px] md:bg-black rounded-[25px] p-[5px] md:shadow-xl cursor-pointer`}
        onClick={toggleModal}>
        <POWER_OFF className='fill-black md:fill-white w-full h-full' />
      </button>
      <Confirmation
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        onConfirm={() => {
          handleSignOut({ navigate });
        }}
      />
    </>
  );
};

export default SignOut;
