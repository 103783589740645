import React, { useState, useEffect } from "react";
import Terminal, { ColorMode, TerminalOutput } from "react-terminal-ui";
import "./rss.css";
import XMLParser from "react-xml-parser";
import { getRssFeed } from "../../api/rss";
import Typewriter from "../../components/Typewriter";
import styled from "styled-components";
import { getFeedUrls } from "../../config/database/rssFeed";
import { fetchSpeech } from "../../api/chat";
import { getSpeechConfigs } from "../../config/database/chat";

const StyledTerminalWrapper = styled.div`
  --font-size-base: 14px;
  --font-size-md: 15px;
  --font-size-lg: 17px;
  --font-size-xl: 18px;
  --font-size-xxl: 20px;

  --padding-base: 20px 20px 35px;
  --padding-md: 35px 20px 35px;
  --padding-lg: 40px 30px 35px;
  --padding-xl: 40px 35px 35px;
  --padding-xxl: 40px 40px 35px;

  .react-terminal-wrapper {
    font-family: poppins;
    width: 100vw;
    height: 100vh;
    font-size: var(--font-size-base);
    padding: var(--padding-base);
    @media (min-width: 576px) {
      font-size: var(--font-size-md);
      padding: var(--padding-md);
    }

    @media (min-width: 768px) {
      font-size: var(--font-size-lg);
      padding: var(--padding-lg);
    }

    @media (min-width: 992px) {
      font-size: var(--font-size-xl);
      padding: var(--padding-xl);
    }

    @media (min-width: 1200px) {
      font-size: var(--font-size-xxl);
      padding: var(--padding-xxl);
    }
  }

  .heading {
    font-weight: 700;
  }

  .description {
    font-weight: 400;
  }

  .messages {
    font-weight: 500;
  }

  .react-terminal-window-buttons {
    display: none;
  }

  .react-terminal-line {
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-wrap: wrap;

    @media (min-width: 576px) {
      padding: 0;
      margin: 0;
    }
  }

  .react-terminal::-webkit-scrollbar {
    display: none;
  }

  .react-terminal {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

const SimpleModal = ({ isOpen, onAccept, onReject }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <p>Do you want to start reading the RSS feed?</p>
        <div className="button-container">
          <button className="modal-button" onClick={onAccept}>
            Accept
          </button>
          <button className="modal-button-alt" onClick={onReject}>
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

const createTerminalMessage = (messages, className) => {
  return (
    <div className={className}>
      <TerminalOutput>
        <Typewriter texts={[messages]} speed={35} startDelay={0} />
      </TerminalOutput>
    </div>
  );
};

const Rss = () => {
  const [feedUrls, setFeedUrls] = useState([]);
  const [baseUrlIndex, setBaseUrlIndex] = useState(0);
  const [feedData, setFeedData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [terminalLineData, setTerminalLineData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permission, setPermission] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [speechConfigData, setSpeechConfigData] = useState({});

  useEffect(() => {
    setLoading(true);
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      setSpeechConfigData(configData);
      setLoading(false);
    };

    fetchSpeechConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchFeedData = async () => {
      setLoading(true);
      const feedUrlData = await getFeedUrls();
      const feedUrlArray = feedUrlData.map((item) => item.feedUrl);
      setFeedUrls(feedUrlArray);
      setLoading(false);
    };

    fetchFeedData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsModalOpen(true);
  }, []);

  const handlePermission = () => {
    setPermission(true);
    setIsModalOpen(false);
  };

  const handleReject = () => {
    setIsModalOpen(false);
  };

  const scrollToBottom = () => {
    const terminalContainer = document.querySelector(".react-terminal");
    if (terminalContainer) {
      const lastItem = terminalContainer.lastChild;
      if (lastItem) {
        setTimeout(() => {
          lastItem.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 0);
      }
    }
  };

  useEffect(() => {
    if (feedUrls && feedUrls.length > 0) {
      fetchRSSFeed();
    }
    // eslint-disable-next-line
  }, [feedUrls]);

  useEffect(() => {
    if (permission && !loading && feedData.length !== 0) {
      speakNextItem();
    }

    // eslint-disable-next-line
  }, [feedData, permission, loading, currentIndex]);

  useEffect(() => {
    scrollToBottom();
  }, [setTerminalLineData]);

  const fetchRSSFeed = async () => {
    try {
      if (feedUrls && feedUrls.length > baseUrlIndex) {
        await getRssFeed(feedUrls[baseUrlIndex])
          .then((data) => {
            let xml = new XMLParser().parseFromString(data);
            if (xml) {
              const items = xml.children[0].children;

              // Filter items with name "item"
              const filteredItems = items.filter(
                (item) => item.name === "item"
              );

              // Take only the first 10 items
              const first10Items = filteredItems.slice(0, 10);
              let filteredData = first10Items.map((item) => {
                let itemData = {};

                item.children.forEach((child) => {
                  if (child.name === "title") {
                    itemData.title = child.value || "";
                  } else if (child.name === "description") {
                    itemData.description = child.value || "";
                  }
                });

                return itemData;
              });

              if (filteredData) {
                setTerminalLineData((prevData) => [...prevData]);
                setFeedData(filteredData);
                setCurrentIndex(0);
                setBaseUrlIndex(baseUrlIndex + 1);
              }
            }
          })
          .catch((err) => console.error(err));
      } else {
        let enterOutput = (
          <TerminalOutput>
            {" "}
            <br />
          </TerminalOutput>
        );

        let noMoreFeedAvailableMessage = createTerminalMessage(
          "=> All feeds have been processed. No more feeds are currently available.",
          "messages"
        );

        setTerminalLineData((prevData) => [
          ...prevData,
          enterOutput,
          noMoreFeedAvailableMessage,
        ]);
        console.error(
          "All feeds have been processed. No more feeds are currently available."
        );
      }
    } catch (error) {
      console.error("Error fetching RSS feed:", error);
    }
  };

  const handleFeedNavigation = () => {
    if (feedData.length - 1 === currentIndex && feedUrls.length > 0) {
      fetchRSSFeed();
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const speakNextItem = async () => {
    if (currentIndex < feedData.length) {
      const { title, description } = feedData[currentIndex];

      // Create terminal messages for title and description
      const titleOutput = createTerminalMessage(title, "heading");
      const descriptionOutput = createTerminalMessage(
        description,
        "description"
      );

      let enterOutput = (
        <TerminalOutput>
          {" "}
          <br />
        </TerminalOutput>
      );

      // Wait for the title speech to finish
      if (title) {
        // Update terminal data to display the title
        setTerminalLineData((prevData) => [
          ...prevData,
          enterOutput,
          titleOutput,
        ]);
        scrollToBottom();
        await fetchSpeech({ text: title, ...speechConfigData });

        if (description) {
          // Update terminal data to display the description
          setTerminalLineData((prevData) => [...prevData, descriptionOutput]);
          scrollToBottom();
          await fetchSpeech({ text: description, ...speechConfigData });
        }
      }

      // Wait for the description speech to finish
      handleFeedNavigation();
    } else {
      console.error("No Feed available at the moment.");
    }
  };

  return (
    <div>
      {!loading && (
        <SimpleModal
          isOpen={isModalOpen}
          onAccept={handlePermission}
          onReject={handleReject}
        />
      )}

      <StyledTerminalWrapper>
        <Terminal colorMode={ColorMode.Dark} height="100%" prompt=">>">
          {loading && createTerminalMessage("Loading...", "messages")}
          {!loading && terminalLineData}
        </Terminal>
      </StyledTerminalWrapper>
    </div>
  );
};

export default Rss;
