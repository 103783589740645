import React from "react";
import Speaker from "../Buttons/Speaker";
import SignOut from "../Buttons/SignOut";

const Header = ({ isMicOn, handleMic }) => {
  return (
    <header className="flex flex-col w-full p-4 bg-bg_chat shadow-2xl lg:flex-row">
      <div className="flex flex-grow justify-between h-[20px] md:h-[65px] lg:h-[80px]  md:justify-around items-center p-0 lg:p:4">
        <SignOut />
        <div>
          <h1 className="text-[26px] font-poppins font-[700] text-black">
            Lara
          </h1>
        </div>
        <Speaker isMicOn={isMicOn} handleMic={handleMic} />
      </div>
    </header>
  );
};

export default Header;
