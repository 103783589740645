import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Chat from "./pages/Chat";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import LoadingScreen from "./components/Spinner/LoadingScreen";
import ForgetPassword from "./pages/ForgetPassword";
import InvalidLink from "./pages/InvalidLink";
import Rss from "./pages/Rss";
import Forms from "./pages/Forms";
import { Provider } from "react-redux";
import { store } from "./store";
import VoiceAssistant from "./pages/voiceAssistant";
import FormRss from "./pages/FormRss";

function App() {
  const [isUser, setIsUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        var uid = user.uid;
        localStorage.setItem("uid", uid); // Store uid in local storage
        setIsUser(uid);
      } else {
        localStorage.removeItem("uid"); // Remove uid from local storage
        setIsUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const getAuthToken = () => {
    setLoading(true);
    const storedUid = localStorage.getItem("uid");
    if (storedUid) {
      setIsUser(storedUid);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAuthToken();
  }, [loading]);

  return (
    <>
      <Provider store={store}>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" element={isUser ? <Chat /> : <Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/invalidLink" element={<InvalidLink />} />
            <Route path="/rss" element={<Rss />} />
            <Route path="/forms/*" element={<Forms />} />
            <Route path="/voice-assistant" element={<VoiceAssistant />} />
            <Route path="/form-rss" element={<FormRss />} />
          </Routes>
        )}
      </Provider>
    </>
  );
}

export default App;
