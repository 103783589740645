import React, { useEffect, useRef } from "react";

const Typewriter = ({ texts, speed = 100, startDelay = 500 }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (!elementRef.current) return;

    let iIndex = 0;
    let iTextPos = 0;
    let iArrLength = texts[0].length;
    let sContents = "";
    let iRow;
    let isCompleted = false;

    const typewriter = () => {
      if (isCompleted) return;

      sContents = "";
      iRow = Math.max(0, iIndex - 20);
      while (iRow < iIndex) {
        sContents += texts[iRow++] + "<br />";
      }
      if (elementRef.current) {
        elementRef.current.innerHTML =
          sContents +
          texts[iIndex].substring(0, iTextPos) +
          (iTextPos === iArrLength ? "" : "_");
      }
      if (iTextPos++ === iArrLength) {
        iTextPos = 0;
        iIndex++;
        if (iIndex !== texts.length) {
          setTimeout(typewriter, startDelay);
        } else {
          isCompleted = true;
        }
      } else {
        setTimeout(typewriter, speed);
      }
    };

    setTimeout(typewriter, startDelay);
  }, [texts, speed, startDelay]);

  return <span ref={elementRef} id="typedtext"></span>;
};

export default Typewriter;