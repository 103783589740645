import React from "react";
import { VOLUME_MUTE, VOLUME_UP } from "../../assets/Images";

const Speaker = ({ handleMic, isMicOn }) => {
  return (
    <div
      className={`flex justify-center items-center w-[45px] h-[45px] md:bg-black rounded-[25px] p-[10px] md:shadow-xl cursor-pointer`}
      onClick={handleMic}>
      {isMicOn ? (
        <VOLUME_UP className='fill-black md:fill-white w-full h-full' />
      ) : (
        <VOLUME_MUTE className='fill-black md:fill-white w-full h-full' />
      )}
    </div>
  );
};

export default Speaker;
