// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'chat',
  initialState: {
    chat: '',
  },
  reducers: {
    setChat: (state, action) => {
      state.chat = action.payload
    },
  }
})

export const { setChat } = counterSlice.actions

export default counterSlice.reducer

export const getChat = (state) => state.chat.chat
