import {
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDocs,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";

async function getDataFromUrl(url) {
  try {
    const formsCollection = collection(db, "forms");
    const q = query(formsCollection, where("url", "==", url));
    const querySnapshot = await getDocs(q);
    const data = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const docData = { id: doc.id, ...doc.data() };
        const count = await getSubmissionCount(docData.id);
        return { ...docData, totalSubmission: count };
      })
    );
    return data;
  } catch (error) {
    console.error("Error fetching data from URL: ", error.message);
    throw error;
  }
}

async function addSubmissionInForms(formId, fields, answer) {
  try {
    const formDocRef = doc(db, "forms", formId);
    const submissionCollectionRef = collection(formDocRef, "submissions");
    await addDoc(submissionCollectionRef, {
      fieldLabelValues: fields,
      answer,
      status: "active",
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error adding submission: ", error);
    throw error;
  }
}

async function getSubmissionCount(formId) {
  try {
    const formDocRef = doc(db, "forms", formId);
    const submissionCollectionRef = collection(formDocRef, "submissions");
    const q = query(submissionCollectionRef, where("status", "==", "active"));
    const submissionCount = await getCountFromServer(q);

    return submissionCount.data().count;
  } catch (error) {
    console.error("Error fetching submission count: ", error);
    throw error;
  }
}

export { getDataFromUrl, addSubmissionInForms };
